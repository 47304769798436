<div class="container">
    <div class="section-title">
        <span class="sub-title">TABLA DE PRECIOS</span>
        <h2>Primer mes sin costo: Evalúa nuestro sistema y descubre cómo simplificar tu gestión.</h2>         
    </div> 
    <div class="row align-items-center justify-content-center">       
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="title">
                    <h3>Hasta 10 Usuarios</h3>
                </div>
                <div class="price">
                    $28 <span>/ Mes</span>
                </div>
                <a routerLink="/contact" class="default-btn">Comenzar</a>
                <ul class="features-list">
                    <li><i class="ri-check-line"></i>Hasta 10 usuarios</li>
                    <li><i class="ri-check-line"></i>Reconocimiento facial</li>
                    <li><i class="ri-check-line"></i>Geolocalización</li>
                    <li><i class="ri-check-line"></i>Notificaciones</li>
                    <li><i class="ri-check-line"></i>Actualizaciones</li>
                    <li><i class="ri-check-line"></i>Soporte 24/7</li>
                </ul>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="single-pricing-box">
                <div class="title">
                    <h3>Selecciona cantidad de usuarios</h3>
                </div>
                <p class="explanatory-text">El precio se calcula en función de los usuarios que efectivamente marquen asistencia.</p>
                <div class="price">
                    USD {{calculatedPrice}} <span>/ Mes</span>
                </div>
                <label for="userRange" class="slider-label">Deslizar para calcular</label>
                <input type="range" min="10" max="1500" [(ngModel)]="selectedUsers" (input)="calculatePrice()" />
                <div class="slider-value" style="margin-top: 15px; margin-bottom: 15px; font-size: 1rem;">
                    {{selectedUsers}} Usuarios
                </div>
                <!-- Input manual para ingresar la cantidad exacta de usuarios -->
                <label for="userInput" class="input-label">O ingresa la cantidad de usuarios:</label>
                <input type="number" id="userInput" min="10" max="1500" [(ngModel)]="selectedUsers" (input)="calculatePrice()" class="user-input" />
                <a routerLink="/contact" class="default-btn btn-limited-width">Comenzar</a>
            </div>
        </div>
    </div>
</div>