<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>¡Gracias por contactarnos!</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" width="121" height="362" alt="image"></div>
</div>

<div class="thank-you-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <h2>¡Tu mensaje ha sido enviado con éxito!</h2>
            <p>Nos pondremos en contacto contigo a la brevedad. Mientras tanto, te invitamos a explorar más sobre nuestros servicios y cómo podemos ayudarte a optimizar la gestión de tu negocio.</p>
        </div>
        <div class="thank-you-actions text-center">
            <a routerLink="/" class="default-btn">Volver al Inicio</a>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
